import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import config from '../config/config'

// import browser from 'browser-detect';
import { useConnect, useNetwork } from 'wagmi'
import { toastAlert } from '../helper/toastAlert';
const WalletModal = (props) => {
  const dispatch = useDispatch();
  const [metamaskenable, setmetamaskenable] = useState(true)
  const { connect, connectors } =
    useConnect()

  const { chain } = useNetwork()

  async function connectfunction(connector) {
    // console.log(connector, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvv')
    try {

      let check = isMobile();

      if (
        check &&
        !window.ethereum && connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }
      var resp = connect(connector)
      // console.log(resp, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvv',chain)
      if (chain && (chain.id != config.NetworkId)) {
        var errorMsg = "Please select " + config.networkName + " on your wallet"
        toastAlert('error', errorMsg, 'network');
      }
      // window.$('#connect_wallet_modal').modal('hide');
      // setTimeout(function(){
      //     window.location.reload(false)
      // },2000)
    } catch (err) {
      console.log(err, 'errrr')
    }



  }



  function isMobile() {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

  // useEffect(() => {
  //   let check = isMobile();
  //   let resultw = browser();
  //   if (check && resultw.name == "safari") {
  //     setmetamaskenable(false)
  //   }
  // }, [])

  return (
    <div
    className="modal fade primary_modal"
    id="connect_wallet_modal"
    tabIndex={-1}
    aria-labelledby="connect_walletModalLabel"
    aria-hidden="true"
    data-bs-dismiss="modal"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="connect_walletModalLabel">
            Connect Wallet
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            <div className="m-2 mb-3">
              {connectors && connectors.length > 0 && connectors.map((connector) => (
                <>

                  {
                    metamaskenable && connector && connector.id == 'metaMask' &&
                    <button type="button" className="connect_wallet_button"
                      key={connector.id}
                      onClick={() => connectfunction({ connector })}>
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  }
                  {
                    connector.id == 'walletConnect' &&
                    <button type="button" className="connect_wallet_button"
                      key={connector.id}
                      onClick={() => connectfunction({ connector })}>
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  }
                </>

              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default WalletModal;