import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import DataTable from "react-data-table-component";
import Navbarinner from "../components/Navbarinner.js";
import { approve, getbalance } from "../Action/contractActions.js";
import { convert } from "../helper/convert.js";
import config from '../config/config.js'
import { useSelector } from "react-redux";
import { checkAllowance, depositBNB, depositBUSD, depositUSDT, getAdminBalance, getTokenfromBnb, getTokenfromBusd } from "../helper/calculation.js";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "is-empty";
import RecentTransactionnModal from "./RecentTransactionModal.js";
import { getUserbuyhistory, getbuyhistory, saveBuyHistory } from "../Action/ApiAction.js";
import { dateFormat } from "../helper/dateformat.js";
import ConfirmationModal from "./ConfirmModal.js";
import { connection } from "../helper/connection.js";
import DefiQIDOABI from '../ABI/DefiqIDO.json'
import { numberFloatOnly, toFixedFormatValue } from "../helper/customMath.js";
import Web3 from "web3";
import { Link } from "react-router-dom";
// import { connection } from "../helper/connection.js";

export default function Buytoken(props) {
  var ConfirmRef = useRef();
  const [load, setLoad] = useState(false)
  const [tokenName, settokenName] = useState('BNB')
  const [fromvalue, setfromvalue] = useState();
  const [toValue, settoValue] = useState();
  const [balance_, setBalance] = useState({})
  const [allbalance, setAllbalance] = useState()
  const [loader, setLoader] = useState(true)
  const [Address, setAddress] = useState('')
  // const [qrrbal, setQrrBalance] = useState(0)
  const [fromError, setfromError] = useState({ insuffucient: "", allowance: "" });
  const [Error, setValidError] = useState('')
  const [transactionHashh, settransactionHash] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [BNBprice, setBNBPrice] = useState(0)
  const [page, setPage] = React.useState(0)
  const [historyList, sethistoryList] = useState([]);
  const [totalRecords, settotalRecords] = useState();
  const [TokenPrice, setTokenPrice] = useState();
  const walletConnection = useSelector((state) => state.walletConnection);


  useEffect(() => {
    getbuyList(1, rowsPerPage)
  }, [walletConnection])

  useEffect(() => {
    getUserDetail()
  }, [walletConnection])

  const getbuyList = async (skip, limit) => {
    setLoader(true)
    let address = walletConnection && walletConnection.address !== "" ? walletConnection.address : ''
    if ((address)) {
      let data = {
        skip: skip,
        limit: limit,
        address: address
      }
      // var { result, loading, totalrecords } = await getbuyhistory(data)
      var { result, loading, totalrecords } = await getUserbuyhistory(data)
      setLoader(loading)
      if (result) {
        sethistoryList(result)
        settotalRecords(totalrecords)
      }
      else {
        sethistoryList([])
        settotalRecords()
      }
    }
    else { }
  }

  const handlePageChange = async (newPage) => {
    setPage(newPage)
    var skip = parseInt(newPage)
    getbuyList(skip, rowsPerPage)
  }

  const handlePerRowsChange = async (event) => {
    setRowsPerPage(event)
    setPage(0)
    getbuyList(1, event)
  }

  // const getUserDetail = async () => {
  //   let address = walletConnection && walletConnection.address !== "" ? walletConnection.address : ''
  //   if (address) {
  //     setAddress(address)
  //   }
  // }
  const getUserDetail = async () => {
    var get = await connection();
    if (get && get.address) {
      setAddress(get.address)
    }
    // var web3 = get.web3;
    var web3 = new Web3(config.BnbRpcUrl);
    let Contractsss = new web3.eth.Contract(DefiQIDOABI, config.DefiqIDO);
    let amount = (parseFloat(1) * (10 ** 18))
    amount = await convert(amount)
    amount = amount.toString()
    var checkDeci = amount.split('.')
    if (checkDeci.length === 2) {
      amount = checkDeci[0]
    }
    let perTokenss = await Contractsss.methods.getTokenfromBnb(amount.toString()).call();
    let perbusdandusdt = await Contractsss.methods.getTokenfromStable(amount.toString()).call();
    perbusdandusdt = perbusdandusdt / 1e18;
    perbusdandusdt = await toFixedFormatValue(parseFloat(perbusdandusdt));
    setTokenPrice(perbusdandusdt)
    perTokenss = perTokenss / 1e18;
    perTokenss = await toFixedFormatValue(parseFloat(perTokenss));
    setBNBPrice(perTokenss)
    // }
  }
  const inputChange = async (e) => {
    const { id, value } = e.target;
    await inputChangeFunction(value, tokenName)
  }

  const inputChangeFunction = async (value, tokenName) => {
    var decimal = 18;
    if (tokenName == "USDT") {
      decimal = 18;
    }
    // var status = await numberFloatOnly(value);
    if (value && parseFloat(value) >= 0) {
      let address = walletConnection && walletConnection.address !== "" ? walletConnection.address : ''
      setValidError('')
      var value_s = value.toString()
      value_s = await convert(value_s)
      var checkDeci = value_s.split('.')
      if (checkDeci.length === 2) {
        if (checkDeci[1] && checkDeci[1].length && checkDeci[1].length > 0) {
          var length = checkDeci[1].length;
          if (length > 8) {
            length = config.toFixedvalue;
          }
          value_s = parseFloat(value_s)
          var resultone = value_s.toFixed(length);
          setfromvalue(resultone)
        }
      }
      else {
        var resultone = value_s;
        setfromvalue(resultone)
      }
      let result = {};
      let balance = {};
      if (tokenName == ('BUSD')) {
        balance = await getbalance(config.BUSDAddress, tokenName);
        setBalance(balance)
        result = await getTokenfromBusd(resultone, tokenName)
        if (result && result.status == true) {
          settoValue(result && result.perToken)
          if (result && parseFloat(result.perToken) > 0) {
            ValidateInsuffucientAllowance(resultone, config.BUSDAddress, balance, result.currency, decimal);
          }
        }
      }
      if (tokenName == ('USDT')) {
        balance = await getbalance(config.USDTAddress, tokenName);
        setBalance(balance)
        result = await getTokenfromBusd(resultone, tokenName)
        if (result && result.status == true) {
          settoValue(result && result.perToken)
          if (result && parseFloat(result.perToken) > 0) {
            ValidateInsuffucientAllowance(resultone, config.USDTAddress, balance, result.currency, decimal);
          }
        }
      }
      else if (tokenName == 'BNB') {
        balance = await getbalance(address, tokenName);
        setBalance(balance)
        result = await getTokenfromBnb(resultone, tokenName)
        if (result && result.status == true) {
          settoValue(result && result.perToken)
        }
      }
    }
    else {
      setfromvalue('')
      settoValue('')
    }

  }

  async function ValidateInsuffucientAllowance(amount, address, balance, symbol, decimals) {
    var { fromallowance, frominsuffucient } = await checkAllowance(amount, address, balance, symbol, decimals);
    if (amount > 0) {
      setfromError({ ...fromError, ...{ "insuffucient": frominsuffucient, "allowance": fromallowance } });
    }
  }

  const handleChange = async (e) => {
    let address = Address
    let value = e.target.value
    settokenName(value)
    let result = {};
    let balance = {};
    var decimal = 18;
    if (value == "USDT") {
      decimal = 18;
    }
    if (value == 'BUSD') {
      balance = await getbalance(config.BUSDAddress, value);
      setBalance(balance)
      result = await getTokenfromBusd(fromvalue, value)
      if (result && result.status == true) {
        setfromvalue(fromvalue)
        settoValue(result && result.perToken)
        ValidateInsuffucientAllowance(fromvalue, config.BUSDAddress, balance, result.currency, decimal);
      }
    } else if (value == 'USDT') {
      balance = await getbalance(config.USDTAddress, value);
      setBalance(balance)
      // console.log(fromvalue, value, 'fromvvvvvvvvvvvsslsjfsfsf')
      result = await getTokenfromBusd(fromvalue, value)
      if (result && result.status == true) {
        setfromvalue(fromvalue)
        settoValue(result && result.perToken)
        ValidateInsuffucientAllowance(fromvalue, config.USDTAddress, balance, result.currency, decimal);
      }
    }
    else if (value == 'BNB') {
      balance = await getbalance(address, value);
      setBalance(balance)
      result = await getTokenfromBnb(fromvalue, value)
      if (result && result.status == true) {
        setfromvalue(fromvalue)
        settoValue(result && result.perToken)
      }
    }
    window.$('#token_modal').modal('hide');
  }

  const BuyTokens = async (e) => {
    e.preventDefault()

    try {
      // console.log(fromvalue, toValue, 'eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
      if (((fromvalue) <= 0 || isEmpty((fromvalue))) || ((toValue) <= 0 || isEmpty((toValue)))) {
        setLoad(false)
        let error = 'Please enter valid amount'
        console.log('error: ', error);
        return setValidError(error)
      }

      else {

        const getadminBal = await getAdminBalance()
        // console.log('getadminBal: ', getadminBal);
        if (parseFloat(getadminBal) < parseFloat(toValue)) {
          setLoad(false)
          return toastAlert('error', 'Insufficient Balance(DefiQ)')
        }
        else {
          let address = walletConnection && walletConnection.address !== "" ? walletConnection.address : ''
          let result = {}
          if (tokenName == 'BUSD') {
            if (fromError.insuffucient == 'yes' || parseFloat(balance_.balance) < parseFloat(fromvalue)) {
              return toastAlert('error', 'Insufficient BUSD Balance')
            }
            if (fromError.allowance === "yes") {
              setLoad(true)
              var { status } = await approve(config.BUSDAddress, fromvalue)
              if (status == true) {
                setLoad(true)
                result = await depositBUSD(tokenName, fromvalue)
              }
              else {
                setLoad(false)
                return toastAlert('error', 'Insufficient Approve Amount')
              }
            }
            else {
              setLoad(true)
              result = await depositBUSD(tokenName, fromvalue)
            }
          }
          else if (tokenName == 'USDT') {

            if (fromError.insuffucient == 'yes' || parseFloat(balance_.balance) < parseFloat(fromvalue)) {
              return toastAlert('error', 'Insufficient USDT Balance')
            }
            if (fromError.allowance === "yes") {
              setLoad(true)
              var { status } = await approve(config.USDTAddress, fromvalue)
              if (status == true) {
                setLoad(true)
                result = await depositUSDT(tokenName, fromvalue)
              }
              else {
                setLoad(false)
                return toastAlert('error', 'Insufficient Approve Amount')
              }
            }
            else {
              setLoad(true)
              result = await depositUSDT(tokenName, fromvalue)
            }
          }
          else if (tokenName == 'BNB') {
            let bnbbalance = await getbalance(address, tokenName);
            let getbnbbal = bnbbalance && bnbbalance.balance
            // console.log(getbnbbal,fromvalue,'fromvaluefromvaluefromvalue')
            if ((parseFloat(getbnbbal) < parseFloat(fromvalue))) {
              setLoad(false)
              return toastAlert('error', `Insufficient ${config.BNBSYMBOL} balance`)
            }

            else {
              setLoad(true)
              result = await depositBNB(tokenName, fromvalue, toValue)
            }
          }
          if (!isEmpty(result)) {
            settransactionHash(result.transactionHash)
            // getUserDetail()
            let data = {
              useraddress: address,
              currency: tokenName,
              depositAmt: parseFloat(fromvalue),
              earnedAmt: parseFloat(toValue),
              transactionHash: result.transactionHash
            }
            let { status } = await saveBuyHistory(data)
            if (status == true) {
              // toastAlert('success', 'Success')
              window.$('#withdraw_success_modal').modal('show');
              setLoad(false)
              setfromvalue(0)
              settoValue(0)
              getbuyList(1, rowsPerPage)
            }
          }
          else {
            setLoad(false)
            settransactionHash('')
            toastAlert('error', 'Transaction Rejected')

          }
        }
      }
    }
    catch (err) {
      console.log('erssr: ', err);

    }
  }

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };
  const columns = [
    {
      name: 'S.no',
      selector: (historyList, index) => index + 1,
      width: "80px"
    },
    {
      name: 'Address',
      width: "250px",
      selector: historyList => historyList.address,
    },
    {
      name: "Deposit",
      selector: historyList => historyList.depositAmt + ' ' + '(' + historyList.currency + ')',
    },
    {
      name: "Received (DefiQ)",
      selector: historyList => historyList.earnedAmt,
    },
    {
      name: "Hash",
      width: "250px",
      selector: (historyList) => <a className="history_table" href={config.txurl + historyList.transactionHash} target="_blank">{historyList.transactionHash}</a>,
    },
    {
      name: 'Date',
      selector: historyList => dateFormat(historyList.createdAt, "DD-MM-YYYY hh:mm A")
    },
  ];

  const data = [
    {
      id: 1,
      sno: '1',
      Address: '0x4Cb3aaE53F9426...',
      BNB: '12',
      Token: '12',
      hash: '0x4Cb3aaE53F9426',
      Date: '02.02.2023',
    },
    {
      id: 2,
      sno: '2',
      Address: '0x4Cb3aaE53F9426...',
      BNB: '12',
      Token: '12',
      hash: '0x4Cb3aaE53F9426',
      Date: '02.02.2023',
    },
    {
      id: 3,
      sno: '3',
      Address: '0x4Cb3aaE53F9426...',
      BNB: '12',
      Token: '12',
      hash: '0x4Cb3aaE53F9426',
      Date: '02.02.2023',
    },
    {
      id: 4,
      sno: '4',
      Address: '0x4Cb3aaE53F9426...',
      BNB: '12',
      Token: '12',
      hash: '0x4Cb3aaE53F9426',
      Date: '02.02.2023',
    },
    {
      id: 5,
      sno: '5',
      Address: '0x4Cb3aaE53F9426...',
      BNB: '12',
      Token: '12',
      hash: '0x4Cb3aaE53F9426',
      Date: '02.02.2023',
    },
    {
      id: 6,
      sno: '6',
      Address: '0x4Cb3aaE53F9426...',
      BNB: '12',
      Token: '12',
      hash: '0x4Cb3aaE53F9426',
      Date: '02.02.2023',
    },
  ]

  // console.log(TokenPrice, BNBprice, 'BNBpriceBNBpriceBNBprice')
  return (
    <div>
      <Navbarinner />
      {/* <Navbar /> */}
      <ConfirmationModal
        ref={ConfirmRef}
        fromvalue={fromvalue}
        toValue={toValue}
        tokenName={tokenName}
        balance_={balance_}
        fromError={fromError}
        getbuyList={getbuyList}
        setValidError={setValidError}
        setfromvalue={setfromvalue}
        settoValue={settoValue}
      />

      <div className="page_box" >
        <section className="buytoken">
          <div className="container">
            <div className="row" >
              <div className="col-lg-8 col-xl-6 mx-auto">
                <div className="buytokenbg">
                  <div className="head mw-100" >
                    <h2 className="h2tag mb-4">Buy Token</h2>
                  </div>
                  <div className="head_box">
                    <h5 className="head_box_price">Current Price: <span>1</span> {tokenName} = <span>{tokenName == 'BNB' ? BNBprice : TokenPrice}</span>  DEFIQ</h5>
                  </div>
                  <div class="input-group mb-4">
                    <select class="form-control mb-0" onChange={handleChange}>
                      <option>BNB</option>
                      {/* <option>BUSD</option> */}
                      <option>USDT</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="mb-2">Enter amount :</label>
                    <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"
                      onChange={inputChange}
                      // value={fromvalue}
                      onInput={validPositive} />
                    {!isEmpty(Error) ? <span className='errortext'>{Error}</span> : ''}
                  </div>


                  <label className="mb-2">You will get: DefiQ</label>
                  <div class="input-group mb-4 pb-3">
                    <input type="text" class="form-control border-end-0" aria-label="Recipient's username" aria-describedby="basic-addon2"
                      readOnly
                      value={toValue} />
                    <span class="input-group-text border-start-0" id="basic-addon2"> DefiQ Token </span>
                  </div>
                  <div className="text-center d-flex justify-content-center" >
                    {
                      (load == false) && walletConnection && walletConnection.address && walletConnection.address !== '' ?
                        // <button type="button" className='primary_btn'  onClick={BuyTokens}>Submit</button>
                        <button type="button" className='primary_btn' onClick={() => ConfirmRef.current.confirm_click()}>Submit</button>


                        : (walletConnection.address == '') ? <button className="primary_btn"
                          data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect wallet</button>
                          : (load == true) ? <button type="button" className='primary_btn' disabled>Processing...please wait</button>
                            : ""
                    }
                    {/* <button className="primary_btn" >  Buy Now</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="table_sec">
          <div className="container">
            <h2 className="h5tag mb-3">  History</h2>
            <DataTable
              columns={columns}
              data={historyList}
              noHeader
              pagination={true}
              paginationServer
              paginationPerPage="5"
              // progressPending={loader}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              paginationTotalRows={totalRecords}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
            />

          </div>
        </section>
      </div>
      {/* <RecentTransactionnModal
        transactionHash={transactionHashh}
      /> */}
      <Footer />
    </div>
  );
}
