import moment from 'moment';

export function dateFormat(date, format) {
	if (date && date !== '' && format && format !== '') {
		var cDate = moment(date).format(format);
		return cDate;
	} else {
		return '-';
	}
}

export function getTimeAgo(dateTime) {
	const customDate = new Date(dateTime);
	const currentDate = new Date();
	const timeDifference = currentDate - customDate;
	const seconds = Math.floor(timeDifference / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);

	if (weeks > 0) {
		return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
	} else if (days > 0) {
		return `${days} day${days > 1 ? 's' : ''} ago`;
	} else if (hours > 0) {
		return `${hours} hour${hours > 1 ? 's' : ''} ago`;
	} else if (minutes > 0) {
		return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
	} else {
		return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
	}
}