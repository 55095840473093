import React from "react";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import config from '../config/config'

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <h6>Smart-contract address: </h6>
            <a className="footer_url" href={config.txUrlAddress + config.DefiqIDO} target="_blank">{config.DefiqIDO}</a>
            <h6>Token-contract address:</h6>
            <a className="footer_url" href={config.txUrlAddress + config.DefiQToken} target="_blank">{config.DefiQToken}</a>
            <Link to="/" >
              <img
                src={logo}
                className="img-fluid brand_logo"
                alt="logo"
              />
            </Link>
          </div>
          <div className="col-lg-4">
            <p>
              Copy right © {new Date().getFullYear()} <a href="#">  DeFiQ</a>,
              All rights Reserved
            </p>
          </div>
          <div className="col-lg-4">
            <ul className="footer_social_links">
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </footer>
  );
}
