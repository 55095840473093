import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import $ from 'jquery';
import Web3 from "web3";
import { setWallet } from "../reducers/Actions";
import { useWalletClient, useAccount, usePublicClient, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { useEthersSigner, walletClientToSigner } from "../helper/ethersconnect"
import WalletModal from "../pages/WalletModal";
import { connection } from '../helper/connection'
import { useDispatch, useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert";
import config from '../config/config'
import { formatAddress, toFixedFormat } from "../helper/customMath";
import { gettokenBalance } from "../Action/contractActions";

var initialvalue = {
  BusdBalance: 0,
  UsdtBalance: 0,
  DefiQBalance: 0
}
export default function Navbarinner(props) {
  let chainId = config.NetworkId;
  const dispatch = useDispatch()
  const location = useLocation();
  const [useraddress, setuseraddress] = useState("");
  const [balance, setbalance] = useState("")
  const { chain } = useNetwork()
  const { disconnect, isSuccess, status } = useDisconnect()
  const [tokenBalance, setTokenBalance] = useState(initialvalue)

  const { data: walletClient } = useWalletClient({ chainId })
  const walletConnection = useSelector((state) => state.walletConnection);
  const { BusdBalance, UsdtBalance, DefiQBalance } = tokenBalance;

  // console.log('walletConnectioonononnnnn: ', walletConnection);


  useAccount({

    onDisconnect: () => {
      localStorage.clear();
      console.log('Disconnected')
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: "",
          address: "",
          provider: "",
          connect: "no",
        })
      );
    },
  }
  )
  useEffect(() => {
    if (isSuccess == true) {
      setTimeout(() => {
        localStorage.clear()
        window.location.reload(false)
      }, 500)
    }
  }, [isSuccess])


  useEffect(() => {
    if (chain && (chain.id !== config.NetworkId)) {
      dispatch(setWallet({
        network: "",
        web3: "",
        address: "",
        provider: "",
        connect: "",
        isChange: "true"
      }));
    } else {
      dispatch(setWallet({
        network: chainId,
        web3: walletConnection.web3,
        address: walletConnection.address,
        provider: walletConnection.provider,
        connect: "yes",
        isChange: "false"
      }))
      // window.location.reload(false)
    }
  }, [walletClient, chain?.network])

  useEffect(() => {
    setConnection()
  }, [walletConnection.network, walletClient])



  useEffect(() => {
    getuserBalance();
    gettokenBalancee()
  }, [walletConnection, walletClient]);


  async function gettokenBalancee() {
    let data = await gettokenBalance(tokenBalance, setTokenBalance)
  }
  useEffect(() => {
    getuserBalance();
  }, [walletConnection, walletClient]);

  async function setConnection() {

    if (chain && chain.id != config.NetworkId) {
      var errorMsg = "Please select " + config.networkName + " on your wallet"
      toastAlert('error', errorMsg, 'network');
      // setTimeout(function () {
      //   disconnect()
      // }, 1000);
    }
    if (walletClient && chain && (chain.id == config.NetworkId)) {

      var { signer, transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);

      dispatch(setWallet({
        network: config.NetworkId,
        web3: web3,
        address: walletClient.account.address,
        provider: transport,
        connect: "yes",
        isChange: "false",
      }));
      getuserBalance();
      var get = await connection()
      // console.log('gesssssssssssssssssst: ', get);
      // console.log(signer, transport, '@@@@@@@@@@@@@@@@@@@1')
    } else {
      dispatch(setWallet({
        network: config.NetworkId,
        web3: null,
        address: "",
        provider: null,
        connect: "yes",
        isChange: "false",
      }));
      // toastAlert('error', "Please select BSC chain network",'network');
    }

  }
  async function getuserBalance() {
    if (walletConnection && walletConnection.web3 && walletConnection.address && walletConnection.address != "") {
      setuseraddress(walletConnection.address);
      var web3 = walletConnection.web3;
      var getBalance = await web3.eth.getBalance(walletConnection.address);
      var bal = getBalance / 10 ** 18;
      bal = await toFixedFormat(bal);
      setbalance(bal);
    }
  }
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      10 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
    });
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-xl main_navbar navbar-sticky">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={logo}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end flex-grow-0"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas" aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* After Login Menus */}
                {/* <div className="dropdown me-3">
                  <a
                    className="primary_btn dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    0x75cf28f29266a413ec878ce5c4a1c9682c819586
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        <label>BUSD Balance:</label>
                        <span className="ms-2">250.08 </span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Dashboard
                      </a>
                    </li>
                  </ul>
                </div>
                <button className="primary_btn">Logout</button> */}

                {/* <div data-bs-dismiss="offcanvas" aria-label="Close">
                <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  Connect Wallet
                  <span className="round"></span>
                </button>
                </div> */}
                <div>
                  {
                    walletConnection && walletConnection.address && walletConnection.address != '' && walletConnection.address != null
                      && walletConnection.connect == "yes" ?
                      <div className="dropdown me-3">
                        <a
                          className="primary_btn dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        // data-bs-target="#connect_wallet_modal"
                        >
                          {formatAddress(useraddress)}
                        </a>
                        <ul
                          className="dropdown-menu walletdrop"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {
                            location.pathname !== "/buytoken" &&
                            <li>
                              <Link to={'/buytoken'} className="dropdown-item">Buy Token </Link>
                            </li>
                          }
                          {
                            location.pathname !== "/" &&
                            <li data-bs-dismiss="offcanvas" aria-label="Close">
                              <Link to={'/'} className="dropdown-item">Home</Link>
                            </li>
                          }
                          <li>
                            <a className="dropdown-item" href="#">
                              <label className="me-2" >BNB Balance:</label>
                              <span>{balance && parseFloat(balance) > 0 ? parseFloat(balance).toFixed(8) : 0}</span>
                            </a>
                          </li>
                          {/* <li>
                            <a className="dropdown-item" href="#">
                              <label className="me-2" >BUSD Balance:</label>
                              <span>{BusdBalance && parseFloat(BusdBalance) > 0 ? parseFloat(BusdBalance) : 0}</span>
                            </a>
                          </li> */}
                          <li>
                            <a className="dropdown-item" href="#">
                              <label className="me-2" >USDT Balance:</label>
                              <span>{UsdtBalance && parseFloat(UsdtBalance) > 0 ? parseFloat(UsdtBalance) : 0}</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <label className="me-2" >DefiQ Balance:</label>
                              <span>{DefiQBalance && parseFloat(DefiQBalance) > 0 ? parseFloat(DefiQBalance) : 0}</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" onClick={() => disconnect()}
                            >
                              Disconnect
                            </a>
                          </li>
                        </ul>
                      </div>
                      :
                      <>
                        <button
                          className="primary_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#connect_wallet_modal"
                        >
                          Connect Wallet
                          <span className="round"></span>
                        </button>
                      </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <WalletModal />

    </>
  );
}
