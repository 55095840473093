import axios from 'axios';
import config from '../config/config';
const baseUrl = config.baseUrl

export const saveBuyHistory = async (data) => {
    try {
        let resp = await axios({
            method: "post",
            url: `${baseUrl}userapi/save-buyhistory`,
            data: data,
        });
        return {
            status: resp.data.status,
        };
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

export const getbuyhistory = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}userapi/get-buyhistory?skip=`
                + data.skip + `&limit=` + data.limit
        })
        return {
            result: respData.data.result,
            loading: false,
            totalrecords: respData.data.totalrecords
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

export const getUserbuyhistory = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}userapi/getuser-buyhistory?skip=` + data.skip
                + `&limit=` + data.limit + `&address=` + data.address
        })
        return {
            result: respData.data.result,
            loading: false,
            totalrecords: respData.data.totalrecords
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors
    } else {
        return ''
    }
}