import React, { useEffect, useState } from "react";


export default function Litepaper() {

  return (
    <div className="container text-center col-lg-6" >
      <img
        src={require("../assets/images/litepaper/img1.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img2.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img3.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img4.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img5.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img6.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img7.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img8.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img9.png")}
        className="img-fluid"
      />
      <img
        src={require("../assets/images/litepaper/img10.png")}
        className="img-fluid"
      />
        <img
        src={require("../assets/images/litepaper/img11.png")}
        className="img-fluid"
      />
    </div>
  );
}
