import {
    SET_WEB3,
    WALLET_CONNECT,
} from '../constant/index';

export const setWeb3 = details => {
    return {
        type: SET_WEB3,
        payload: details
    };
};

export const setWallet = details => {
    return {
        type: WALLET_CONNECT,
        payload: details
    };
};


