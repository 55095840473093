import DefiqICOABI from '../ABI/DefiqIDO.json'
import ABI from '../ABI/BEP20ABI.json'
import config from '../config/config'
import { convert } from './convert'
import { allowance, allowanceDefiq } from '../Action/contractActions'
import { toFixedFormatValue } from './customMath'
import isEmpty from 'is-empty'

import { connection } from './connection'
import Web3 from 'web3'

export async function getTokenfromBusd(value, currency) {
    // console.log('valusssssssssssssrency: ', value, currency);
    var get = await connection();
    try {
        // if (get && get.web3) {
            // var web3 = get.web3;
            var web3 = new Web3(config.BnbRpcUrl);
            if (currency == 'BUSD' || 'USDT') {
                if (!isEmpty(value)) {
                    // console.log('valddddddddddddue: ', value);
                    let Contractsss = new web3.eth.Contract(DefiqICOABI, config.DefiqIDO);
                    let amount = parseFloat(value) * (10 ** 18)
                    amount = await convert(amount)
                    amount = amount.toString()
                    var checkDeci = amount.split('.')
                    if (checkDeci.length === 2) {
                        amount = checkDeci[0]
                    }
                    let perToken = await Contractsss.methods.getTokenfromStable((amount).toString()).call();
                    perToken = perToken / 1e18;
                    perToken = await toFixedFormatValue(parseFloat(perToken));
                    return {
                        status: true,
                        perToken: perToken,
                        currency: currency
                    }
                // }
                // else {
                //     return {
                //         status: true,
                //         // perToken: 0,
                //         perToken: '',
                //         currency: currency
                //     }
                // }
            }
        } else {
            return {
                status: true,
                perToken: 0,
                currency: currency
            }
        }
    }
    catch (err) {
        console.log('errerr: ', err);
        return {
            status: false,
            perToken: 0,
            currency: currency
        }
    }
}

export async function getTokenfromBnb(value, currency) {
    var get = await connection();
    try {
        // if (get && get.web3) {
            // var web3 = get.web3;
            var web3 = new Web3(config.BnbRpcUrl);
            if (currency == 'BNB') {
                if (!isEmpty(value)) {
                    let Contractsss = new web3.eth.Contract(DefiqICOABI, config.DefiqIDO);
                    let amount = (parseFloat(value) * (10 ** 18))
                    amount = await convert(amount)
                    amount = amount.toString()
                    var checkDeci = amount.split('.')
                    if (checkDeci.length === 2) {
                        amount = checkDeci[0]
                    }
                    let perToken = await Contractsss.methods.getTokenfromBnb(amount.toString()).call();
                    perToken = perToken / 1e18;
                    perToken = await convert(perToken)
                    perToken = await toFixedFormatValue(parseFloat(perToken));
                    return {
                        status: true,
                        perToken: perToken,
                        currency: currency
                    }
                } else {
                    return {
                        status: true,
                        // perToken: 0,
                        perToken: '',
                        currency: currency
                    }
                }
            // }
            // else {
            //     return {
            //         status: true,
            //         perToken: 0,
            //         currency: currency
            //     }
            // }
        }
    }
    catch (err) {
        return {
            status: false,
            perToken: 0,
            currency: currency
        }
    }
}

export async function getPriceBNB(amount) {
    var get = await connection();
    try {
        // if (get && get.web3) {
            // var web3 = get.web3;
            var web3 = new Web3(config.BnbRpcUrl);
            const Contract = new web3.eth.Contract(DefiqICOABI, config.DefiqIDO);
            let price = await Contract.methods.getBnbPrice().call();
            let tokenbusd = await Contract.methods.getTokenprice().call();
            let last_0 = parseFloat(amount) / (tokenbusd / 1000000)
            let last = parseFloat(last_0 / (price / 1e16)).toFixed(4);
            let saved_one = parseFloat(last_0 / (price / 1e16))
            saved_one = await convert(saved_one)
            saved_one = await toFixedFormatValue(parseFloat(saved_one));

            return {
                inputCal: saved_one,
                inputvalue: last
            }
        // }
        // else {
        //     return {
        //         inputCal: 0,
        //         inputvalue: 0
        //     }
        // }

    }
    catch (err) {
        return {
            inputCal: 0,
            inputvalue: 0
        }

    }
}

export async function getPriceBUSD(amount) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            let Contract = new web3.eth.Contract(DefiqICOABI, config.DefiqIDO);
            let price = await Contract.methods.getTokenprice().call();
            let cal_price_0 = parseFloat(amount) / (parseFloat(price) / 1000000)
            cal_price_0 = await toFixedFormatValue(parseFloat(cal_price_0));
            let cal_price = parseFloat(cal_price_0).toFixed(4)

            return {
                inputCal: cal_price_0,
                inputvalue: cal_price
            }
        }
        else {
            return {
                inputCal: 0,
                inputvalue: 0
            }
        }
    }
    catch (err) {
        return {
            inputCal: 0,
            inputvalue: 0
        }
    }
}

export async function depositBUSD(tokenname, amount) {
    // console.log('tokennassssssssssssunt: ', tokenname, amount);
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            let Contractsss = new web3.eth.Contract(DefiqICOABI, config.DefiqIDO);
            let sendAmt = amount * (10 ** 18)
            sendAmt = await convert(sendAmt)
            sendAmt = sendAmt.toString()
            var checkDeci = sendAmt.split('.')
            if (checkDeci.length === 2) {
                sendAmt = checkDeci[0]
            }
            let perToken = await Contractsss.methods.depositBUSD(sendAmt.toString()).send({ from: address })
            return perToken
        }
        else {
            return 0
        }
    }
    catch (err) {
    }
}

export async function depositUSDT(tokenname, amount) {
    // console.log('tokennassssssssssssunt: ', tokenname, amount);
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            let Contractsss = new web3.eth.Contract(DefiqICOABI, config.DefiqIDO);
            let sendAmt = amount * (10 ** 18)
            sendAmt = await convert(sendAmt)
            sendAmt = sendAmt.toString()
            var checkDeci = sendAmt.split('.')
            if (checkDeci.length === 2) {
                sendAmt = checkDeci[0]
            }
            let perToken = await Contractsss.methods.depositUSDT(sendAmt.toString()).send({ from: address })
            return perToken
        }
        else {
            return 0
        }
    }
    catch (err) {
    }
}
export async function depositBNB(tokenname, amount) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            let Contractsss = new web3.eth.Contract(DefiqICOABI, config.DefiqIDO);
            let sendAmt = amount * (10 ** 18)
            sendAmt = await convert(sendAmt)
            sendAmt = sendAmt.toString()
            var checkDeci = sendAmt.split('.')
            if (checkDeci.length === 2) {
                sendAmt = checkDeci[0]
            }
            let perToken = await Contractsss.methods.depositBNB().send({ from: address, value: sendAmt.toString() })
            return perToken
        }
        else {
            return 0
        }
    }
    catch (err) {
    }
}

export async function checkAllowance(amount, address, balance, symbol, decimals) {
    try {
        var fromallowance = "no";
        var frominsuffucient = "no";
        var amount = parseFloat(amount) * (10 ** 18)
        var value1 = await allowance(address, config.DefiqIDO);
        var bal = value1.value;
        var balanceof = parseFloat(balance.balanceOf)
        balanceof = parseFloat(balanceof) / 10 ** parseFloat(decimals);
        bal = parseFloat(bal) / 10 ** parseFloat(decimals);
        amount = parseFloat(amount) / 10 ** parseFloat(decimals);
        if (amount > 0) {
            if (parseFloat(bal) < parseFloat(amount) && symbol !== config.BNBSYMBOL) {
                fromallowance = "yes";
            }
            if (parseFloat(amount) > parseFloat(balanceof)) {
                frominsuffucient = "yes";
            }
            return {
                fromallowance,
                frominsuffucient,
            }
        }
        else {
            return {
                fromallowance,
                frominsuffucient,
            }
        }
    }
    catch (err) {
        return {
            fromallowance: "no",
            frominsuffucient: "no"
        }
    }
}

export async function getAdminBalance() {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            const tokenContract = new web3.eth.Contract(ABI, config.DefiQToken);
            var adminbal = await tokenContract.methods.balanceOf(config.DefiqIDO).call();
            adminbal = parseFloat(adminbal) / 10 ** 18
            return adminbal
        }
        else {
            return 0
        }
    }
    catch (err) {
    }
}

export async function getAllowanceDetails() {
    try {
        let { value, status } = await allowanceDefiq(config.StakingContract);
        let values = parseFloat(value)
        values = values / 10 ** 18
        if (status == true) {
            return values
        }
        else {
            return 0
        }
    }
    catch (err) {
    }

}


