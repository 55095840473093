import ABI from '../ABI/BEP20ABI.json'
import { divideDecimal } from "../helper/customMath";
import config from '../config/config'
import { convert } from "../helper/convert";
import { connection } from "../helper/connection";
import { Multicall } from 'ethereum-multicall';
import Web3 from 'web3';
import { walletClientToSigner } from '../helper/ethersconnect';

export async function getbalance(from, symbol) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var balanceOf = 0;
            var bal = 0;
            if (symbol === config.BNBSYMBOL) {
                var getBalance = await web3.eth.getBalance(address);
                balanceOf = getBalance;
                bal = balanceOf / 10 ** 18
            } else {
                var Contract = new web3.eth.Contract(ABI, from);////
                balanceOf = await Contract.methods.balanceOf(address).call();
                var decimals = await Contract.methods.decimals().call();
                bal = await divideDecimal(balanceOf, decimals);
                bal = await convert(bal);
                // console.log('bssssssssssssssal: ', bal);
            }
            return {
                balance: bal,
                balanceOf: balanceOf,
                error: ""
            };
        } else {
            return {
                balance: 0,
                balanceOf: 0,
                error: ""
            };
        }
    } catch (err) {
        return {
            balance: 0,
            balanceOf: 0,
            error: err
        };
    }
}

export async function allowance(contractAddr, spender) {//busdadd,quantumico
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var owner = get.address;
            var Contract = new web3.eth.Contract(ABI, contractAddr);
            var result1 = await Contract.methods.allowance(
                owner,
                spender
            ).call();
            return {
                value: result1,
                status: true
            };
        } else {
            return {
                value: {},
                status: false
            };
        }
    } catch (err) {
        return {
            value: {},
            status: false
        };
    }
}
//allowance-defiq
export async function allowanceDefiq(spender) {
    //spender - defiqidocontract
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var owner = get.address;
            var Contract = new web3.eth.Contract(ABI, config.DefiQToken);
            var result1 = await Contract.methods.allowance(
                owner,
                spender
            ).call();
            return {
                value: result1,
                status: true
            };
        } else {
            return {
                value: {},
                status: false
            };
        }
    } catch (err) {
        return {
            value: {},
            status: false
        };
    }
}
export async function approve(contractAddr, fromvalue) {
    // console.log('contractAddssssssssssr: ', contractAddr,fromvalue,'sssssssssssssssssssss');
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(ABI, contractAddr);
            var approveAmt = 1000000000 * (10 ** 18);
            approveAmt = await convert(approveAmt);
            var result1 = await Contract.methods.approve(
                config.DefiqIDO,
                approveAmt.toString()
            ).send({ from: address });
            var approvereturnval = result1 && result1.events && result1.events.Approval && result1.events.Approval.returnValues && result1.events.Approval.returnValues.value ?
                result1.events.Approval.returnValues.value : 0
            approvereturnval = parseFloat(approvereturnval) / 10 ** 18
            if (parseFloat(approvereturnval) < parseFloat(fromvalue)) {
                return {
                    value: {},
                    status: false
                };
            }
            else {
                return {
                    value: result1,
                    approvereturnval: approvereturnval,
                    status: true
                };
            }
        } else {
            return {
                value: {},
                status: false
            };
        }
    } catch (err) {
        return {
            value: {},
            status: false
        };
    }
}
//approveQRRtoken
export async function approveDefiqtoken(contractAddr, stakeval) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(ABI, contractAddr);
            var approveAmt = 1000000000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var bal = await Contract.methods.balanceOf(address).call();
            bal = parseFloat(bal) / (10 ** 18);
            if (parseFloat(bal) < parseFloat(stakeval)) {
                return {
                    value: {},
                    approvereturnval: 0,
                    status: false,
                    message: "Insufficient balance"
                };
            }
            var result1 = await Contract.methods.approve(
                config.StakingContract,
                approveAmt.toString()
            ).send({ from: address });
            var approvereturnval = result1 && result1.events && result1.events.Approval && result1.events.Approval.returnValues && result1.events.Approval.returnValues.value ?
                result1.events.Approval.returnValues.value : 0
            approvereturnval = parseFloat(approvereturnval) / 10 ** 18
            return {
                value: result1,
                approvereturnval: approvereturnval,
                status: true,
                message: 'Approved success'

            };
        } else {
            return {
                value: {},
                approvereturnval: 0,
                status: false,
                message: "Approved Failed"

            };
        }
    } catch (err) {
        return {
            value: {},
            approvereturnval: 0,
            status: false,
            message: "Approved Failed"
        };
    }
}

export async function getDefiqBalance() {
    var get = await connection()
    try {
        if (get && get.web3 && get.address) {
            var address = get.address;
            var web3 = get.web3;
            var Contract = new web3.eth.Contract(ABI, config.DefiQToken);
            var balanceOf = await Contract.methods.balanceOf(address).call();
            var decimals = await Contract.methods.decimals().call();
            var bal = await divideDecimal(balanceOf, decimals);
            bal = await convert(bal);
            return {
                balance: bal,
                balanceOf: balanceOf,
                error: ""
            };
        }
    }
    catch (err) {
        return {
            balance: 0,
            balanceOf: 0,
            error: ""
        };
    }
}


export async function gettokenBalance(tokenBalance,setTokenBalance) {

    var get = await connection()
    try {
        if (get && get.web3 && get.address) {
            // if (walletConnection && walletConnection.address && walletClient && chain && (chain.id == config.NetworkId)) {
            let useraddress = get.address;
            // var { transport } = walletClientToSigner(walletClient);
            var web3 = get.web3;
            const multicall = new Multicall({
                web3Instance: web3,
            })
            var contractCallContext = [
                {
                    reference: "BusdBalance",
                    contractAddress: config.BUSDAddress,
                    abi: ABI,
                    calls: [
                        {
                            reference: "balanceOf",
                            methodName: "balanceOf",
                            methodParameters: [useraddress]
                        }
                    ],
                },
                {
                    reference: "UsdtBalance",
                    contractAddress: config.USDTAddress,
                    abi: ABI,
                    calls: [
                        {
                            reference: "balanceOf",
                            methodName: "balanceOf",
                            methodParameters: [useraddress]
                        }
                    ],
                },
                {
                    reference: "DefiQbalance",
                    contractAddress: config.DefiQToken,
                    abi: ABI,
                    calls: [
                        {
                            reference: "balanceOf",
                            methodName: "balanceOf",
                            methodParameters: [useraddress]
                        }
                    ],
                },
            ];

            const results = await multicall.call(contractCallContext);
            var BUSDBal = await getFormatMulticall(results, "BusdBalance", 0);
            BUSDBal = await divideDecimal(parseInt(BUSDBal.hex), 18);
            var UsdtBal = await getFormatMulticall(results, "UsdtBalance", 0);
            UsdtBal = await divideDecimal(parseInt(UsdtBal.hex), 18);
            var DefiQbal = await getFormatMulticall(results, "DefiQbalance", 0);
            DefiQbal = await divideDecimal(parseInt(DefiQbal.hex), 18);
            setTokenBalance({
                ...tokenBalance, ...{
                    'UsdtBalance': UsdtBal,
                    'BusdBalance': BUSDBal,
                    'DefiQBalance': DefiQbal
                }
            })
        }
    }
    catch (err) {
        return {
            UsdtBalance:0,
            BusdBalance:0,
            DefiQBalance:0
        };
    }
}
async function getFormatMulticall(results, name, pos) {
    try {
        var returnVal =
            results &&
                results.results &&
                results.results[name].callsReturnContext &&
                results.results[name].callsReturnContext &&
                results.results[name].callsReturnContext[pos] &&
                results.results[name].callsReturnContext[pos].returnValues &&
                results.results[name].callsReturnContext[pos].returnValues[0]
                ? (results.results[name].callsReturnContext[pos].returnValues.length > 1) ?
                    results.results[name].callsReturnContext[pos].returnValues : results.results[name].callsReturnContext[pos].returnValues[0]
                : "";

        return returnVal;
    } catch (err) {
        return "";
    }
}
