import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer.js";
import Navbarinner from "../components/Navbarinner.js";
import { convert } from "../helper/convert.js";
import config from '../config/config.js'
import { useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "is-empty";
import { connection } from "../helper/connection.js";
import { numberFloatOnly, toFixedFormatValue } from "../helper/customMath.js";
import Web3 from "web3";
import { Link } from "react-router-dom";
import DefiQIDOABI from '../ABI/DefiqIDO.json'
import { getAdminBalance } from "../helper/calculation.js";

var initialValues = {
    "tokenprice": '',
    "claimAmt": 0,
    "address": ''
}
export default function Admin(props) {
    const [load, setLoad] = useState(false)
    const [Error, setValidError] = useState("")
    const [values, setValues] = useState(initialValues)
    const { tokenprice, claimAmt, address } = values;
    const walletConnection = useSelector((state) => state.walletConnection);
    const [ErrorClaim, setErrorClaim] = useState({})
    const [loadclaim, setLoadclaim] = useState(false)

    const handleChange = async (e) => {
        const { name, value } = e.target
        setValues({ ...values, ...{ [name]: value } })
        setValidError("")
        setErrorClaim({})
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        if (isEmpty(tokenprice)) {
            let errors = "Please fill valid token price"
            return setValidError(errors)
        }
        var get = await connection()
        try {
            if (get && get.web3) {
                setLoad(true)
                var address = get.address;
                var web3 = get.web3;
                var contract = new web3.eth.Contract(DefiQIDOABI, config.DefiqIDO)
                let admin = await contract.methods.owner().call();
                if ((address).toLowerCase() !== (admin).toLowerCase()) {
                    setLoad(false)
                    return toastAlert('error', 'Please connect to admin wallet address')
                }
                var amt = parseFloat(tokenprice);
                amt = 1 / amt;
                amt = amt * (10 ** 6);
                amt = amt.toString();
                var checkDeci = amt.split('.')
                if (checkDeci.length === 2) {
                    amt = checkDeci[0]
                }
                amt = await convert(amt)
                var data = await contract.methods.settoken(amt.toString()).send({ from: address })
                if (data && data.transactionHash) {
                    setLoad(false)
                    setValues(initialValues)
                    return toastAlert('success', 'Price updated successfully')
                }
                else {
                    setLoad(false)
                    return toastAlert('error', 'Transaction Rejected')
                }
            } else {
                setLoad(false)
                return toastAlert('error', 'Please connect to admin wallet address')
            }

        } catch (err) {
            setLoad(false)
            console.log('err: ', err);
            return toastAlert('error', 'Transaction Rejected')
        }
    }

    const handlesubmitClaim = async (e) => {
        e.preventDefault();
        let error = {};
        if (isEmpty(address)) {
            error.address = "Please fill valid address"
        }
        if (isEmpty(claimAmt) || (claimAmt <= 0)) {
            error.claimAmt = "Please fill valid amount"
        }
        if (!isEmpty(error)) {
            return setErrorClaim(error)
        }
        var get = await connection()
        try {
            if (get && get.web3) {
                setLoadclaim(true)
                var fromaddress = get.address;
                var web3 = get.web3;
                var gasPrice = await web3.eth.getGasPrice();
                var contract = new web3.eth.Contract(DefiQIDOABI, config.DefiqIDO)
                let admin = await contract.methods.owner().call();
                var checkaddress = web3.utils.isAddress(address);
                let balance = await getAdminBalance()
                console.log(claimAmt, 'balancsssssssssse: ', balance);
                if (parseFloat(balance) < parseFloat(claimAmt)) {
                    setLoadclaim(false)
                    return toastAlert('error', 'Insufficient amount to claim')
                }
                if (!checkaddress) {
                    setLoadclaim(false)
                    return toastAlert('error', 'Please enter valid address')
                }
                if ((fromaddress).toLowerCase() !== (admin).toLowerCase()) {
                    setLoadclaim(false)
                    return toastAlert('error', 'Please connect to admin wallet address')
                }

                var amt = parseFloat(claimAmt);
                amt = amt * (10 ** 18);
                amt = amt.toString();
                var checkDeci = amt.split('.')
                if (checkDeci.length === 2) {
                    amt = checkDeci[0]
                }
                amt = await convert(amt)
                console.log('amssssssssssssst: ', amt);
                var estimate = await contract.methods.safeWithDrawDefiQ(amt.toString(), address.toString()).estimateGas({ from: fromaddress, gasPrice: gasPrice })
                var estimateGas = estimate + 30000;
                var data = await contract.methods.safeWithDrawDefiQ(amt.toString(), address.toString()).send({ from: fromaddress, gasPrice: gasPrice, gasLimit: estimateGas })
                if (data && data.transactionHash) {
                    setLoadclaim(false)
                    setValues(initialValues)
                    return toastAlert('success', 'Claimed Successfully')
                }
                else {
                    setLoadclaim(false)
                    return toastAlert('error', 'Transaction Rejected')
                }
            } else {
                setLoadclaim(false)
                return toastAlert('error', 'Please connect to admin wallet address')
            }

        } catch (err) {
            setLoadclaim(false)
            console.log('err: ', err);
            return toastAlert('error', 'Transaction Rejected')
        }
    }

    const validPositive = (e) => {
        if (
            new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };



    console.log(Error, 'tokenpricetokenpricetokenprice')
    return (
        <div>
            <Navbarinner />

            <div className="page_box" >
                <section className="buytoken">
                    <div className="container">
                        <div className="row" >
                            <div className="col-lg-8 col-xl-6 mx-auto">
                                <div className="buytokenbg">
                                    <div className="head mw-100" >
                                        <h2 className="h2tag mb-4">DefiQ-IDO</h2>
                                    </div>

                                    <div className="mb-4">
                                        <label className="mb-2">Token Price :</label>
                                        <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                            onChange={handleChange}
                                            name="tokenprice"
                                            value={tokenprice}
                                            onInput={validPositive} />
                                        {!isEmpty(Error) ? <span className='errortext'>{Error}</span> : ''}
                                    </div>

                                    <div className="text-center d-flex justify-content-center" >
                                        {
                                            (load == false) && walletConnection && walletConnection.address && walletConnection.address !== '' ?
                                                <button type="button" className='primary_btn' onClick={handlesubmit}>Submit</button>
                                                : (walletConnection.address == '') ? <button className="primary_btn"
                                                    data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect wallet</button>
                                                    : (load == true) ? <button type="button" className='primary_btn' disabled>Processing...please wait</button>
                                                        : ""
                                        }
                                    </div>


                                    <div className="mb-4">
                                        <label className="mb-2">Withdraw (DefiQ Token):</label>
                                        <div>
                                            <label className="mb-2">Enter Amount:</label>
                                            <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                                onChange={handleChange}
                                                name="claimAmt"
                                                value={claimAmt}
                                                onInput={validPositive} />
                                            {!isEmpty(ErrorClaim?.claimAmt) ? <span className='errortext'>{ErrorClaim?.claimAmt}</span> : ''}

                                            <div>
                                                <label className="mb-2">Enter Address:</label>
                                                <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                                    onChange={handleChange}
                                                    name="address"
                                                    value={address}
                                                />
                                                {!isEmpty(ErrorClaim?.address) ? <span className='errortext'>{ErrorClaim?.address}</span> : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center d-flex justify-content-center" >
                                        {
                                            (loadclaim == false) && walletConnection && walletConnection.address && walletConnection.address !== '' ?
                                                <button type="button" className='primary_btn' onClick={handlesubmitClaim}>Submit</button>

                                                : (walletConnection.address == '') ? <button className="primary_btn"
                                                    data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect wallet</button>
                                                    : (loadclaim == true) ? <button type="button" className='primary_btn' disabled>Processing...please wait</button>
                                                        : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}